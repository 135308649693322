import { DefaultButton, makeStyles, Stack } from '@fluentui/react';
import { Image } from '@fluentui/react/lib/Image';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { useGlobalState } from 'src/core/hooks/useGlobalState';
import { useLocalization } from 'src/core/hooks/useLocalization';
import dismiss from './assets/dismiss_icon.svg';
import bg from './assets/header_banner.svg';
import logo from './assets/header_icon.svg';

const messages = defineMessages({
    title: { id: 'languageStudio.banner.title', defaultMessage: 'Try new features in Azure AI Studio' },
    content: {
        id: 'languageStudio.banner.content',
        defaultMessage:
            'Azure AI Speech tools are coming to Azure AI Studio soon. Check out Fast Translation and other Speech features now.'
    },
    close: {
        id: 'languageStudio.banner.close',
        defaultMessage: 'close'
    },
    button: { id: 'languageStudio.banner.button', defaultMessage: 'Visit AI Studio' }
});

const useStyles = makeStyles({
    container: {
        padding: '0 30px',
        minHeight: '100px',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        position: 'relative',
        backgroundColor: '#F4F4F6',
        '@media (max-width: 767px)': {
            padding: '0 10px',
            minHeight: '80px',
            gap: '10px'
        }
    },
    bannerLogo: {
        userSelect: 'none'
    },
    bannerBg: {
        position: 'absolute',
        height: '100%',
        right: '0'
    },
    bannerBgImage: { width: 'fit-content', position: 'absolute', height: '100%', right: '0' },
    bannerContent: { flexGrow: '1', zIndex: '1', userSelect: 'none' },
    title: {
        fontFamily: 'Segoe UI',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '22px',
        '@media (max-width: 767px)': {
            fontSize: '12px',
            lineheight: '16px'
        }
    },
    content: {
        fontFamily: 'Segoe UI',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
        '@media (max-width: 767px)': {
            fontSize: '10px',
            lineHeight: '12px'
        }
    },
    redirect: {
        fontFamily: 'Segoe UI',
        fontWeight: '600',
        fontSize: '16px',
        '@media (max-width: 767px)': {
            fontSize: '10px',
            padding: '0'
        }
    },
    dismiss: {
        padding: '0',
        cursor: 'pointer'
    }
});

const Banner = () => {
    const { rootStore } = useGlobalState();
    const { localize } = useLocalization();
    const styles = useStyles();
    const onRedirect = () => {
        const url = 'https://ai.azure.com/explore/language';
        window.open(url);
    };
    const onDismiss = () => {
        sessionStorage.setItem('shouldHideBanner', 'true');
        rootStore.setShouldHideBanner();
    };
    return (
        <Stack className={styles.container}>
            <Stack className={styles.bannerBg}>
                <Image src={bg} imageFit={3} className={styles.bannerBgImage} />
            </Stack>
            <Image src={logo} width={48} height={48} className={styles.bannerLogo} />
            <Stack className={styles.bannerContent}>
                <Stack className={styles.title}>{localize(messages.title)}</Stack>
                <Stack className={styles.content}>{localize(messages.content)}</Stack>
            </Stack>
            <DefaultButton onClick={onRedirect} className={styles.redirect}>
                {localize(messages.button)}
            </DefaultButton>
            <button className={styles.dismiss} onClick={onDismiss} type="button" aria-label={localize(messages.close)}>
                <Image src={dismiss} width={20} height={20} />
            </button>
        </Stack>
    );
};

export default Banner;
